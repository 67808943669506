/* eslint-disable max-lines */
import { useAuth } from '../../../contexts/AuthContext'
import { IMAGES } from '../../../assets/images/images'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useState } from 'react'
import CheckoutModal from '../../../components/CheckoutModal/CheckoutModal'
// AUTH_PATH
import { ACCOUNT_PATH, PAGE_PATH } from '../../../router/paths'
import classNames from 'classnames'
import ParentDropdown from '../../../components/ParentDropDown/ParentDropdown'
import { useQueryClient } from 'react-query'
import { useParent } from '../../../contexts/ParentContext'
// import { RoleMap } from '../../../config/constants'
import LoginPopup from '../../../sections/auth/login/LoginPopup'
const Header = () => {
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const { logout, isAuthenticated: isAfterLogin, user } = useAuth()
  const { parentId } = useParent()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLoginOpen, setIsLoginOpen] = useState(false)
  const [showCart, setShowCart] = useState(false)
  const cartItems = queryClient.getQueryData(['carts', parentId]) || []
  const isActive = (path) => path === pathname

  const handleLoginPopup = () => {
    setIsLoginOpen(!isLoginOpen)
  }

  return (
    <>
      <header className='header-cntr'>
        <nav className={`header-box ${isAfterLogin && 'header-after-login'}`}>
          <div className='row align-items-center'>
            <div className={`${isAfterLogin ? 'col-3 col-lg-5' : 'col-3 col-md-5'}`}>
              <nav className='nav-menu-list navbar-expand-lg js-nav-menu-list'>
                <button
                  className='menu-toggle js-menu-toggle d-block d-lg-none'
                  onClick={() => setIsMenuOpen((prevState) => !prevState)}
                >
                  <i className='fa fa-bars' aria-hidden='true'></i>
                </button>
                {isAfterLogin ? (
                  <ul
                    className={`menu-list navbar-nav js-menu-list ${isMenuOpen ? 'show-menu' : ''}`}
                  >
                    <li
                      className={classNames('nav-item', {
                        active: isActive(ACCOUNT_PATH.list('Tees,Sweats')),
                      })}
                    >
                      <NavLink to={ACCOUNT_PATH.list('Tees,Sweats')} className='nav-link'>
                        Shop All
                      </NavLink>
                    </li>
                    <li
                      className={classNames('nav-item', {
                        active: isActive(ACCOUNT_PATH.list('Tees')),
                      })}
                    >
                      <NavLink to={ACCOUNT_PATH.list('Tees')} className='nav-link'>
                        Tees
                      </NavLink>
                    </li>
                    <li
                      className={classNames('nav-item', {
                        active: isActive(ACCOUNT_PATH.list('Sweats')),
                      })}
                    >
                      <NavLink to={ACCOUNT_PATH.list('Sweats')} className='nav-link'>
                        Sweats
                      </NavLink>
                    </li>
                    <li className={'d-lg-none'}>
                      <div className='header-login after-login'>
                        <ul className='login'>
                          {/* <li className='login-item js-login-item'>
                            <button
                              type={'button'}
                              className='btn rounded-0 item js-login-link-item'
                            >
                              Search
                            </button>
                          </li> */}
                          <li className='login-item js-login-item'>
                            <button className='btn rounded-0 item js-login-link-item' disabled>
                              <Link
                                to={ACCOUNT_PATH.account()}
                                className='item js-login-link-item1'
                              >
                                Account
                              </Link>
                            </button>
                          </li>
                          <li className='login-item js-login-item'>
                            <button
                              type={'button'}
                              className='btn rounded-0 item js-login-link-item'
                              disabled={!user?.isUser}
                            >
                              My Bag <span className='text-danger'>(2)</span>
                            </button>
                          </li>
                          <li className='login-item js-login-item'>
                            <button
                              type={'button'}
                              className='btn rounded-0 item js-login-link-item'
                              onClick={() => logout()}
                              disabled={!user?.isUser}
                            >
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <ul
                    className={`menu-list navbar-nav js-menu-list ${isMenuOpen ? 'show-menu' : ''}`}
                  >
                    <li className='nav-item active'>
                      <NavLink to='/' className='nav-link'>
                        Home
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink to={PAGE_PATH.about_us} className='nav-link'>
                        About Us
                      </NavLink>
                    </li>
                    <li className='nav-item'>
                      <NavLink to={PAGE_PATH.contact_us} className='nav-link'>
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                )}
              </nav>
            </div>
            <div className={`${isAfterLogin ? 'col-9 col-lg-2' : 'col-6 col-md-2'}`}>
              <div className='brand-logo'>
                <Link to={PAGE_PATH.home}>
                  <img src={IMAGES.Logo} alt={'logo'} />
                </Link>
              </div>
            </div>
            <div className={`${isAfterLogin ? 'col-9 col-lg-5' : 'col-3 col-md-5'}`}>
              <div className='header-login'>
                {isAfterLogin ? (
                  <ul className='login d-none d-lg-flex'>
                    <li className='login-item js-login-item'>
                      <ParentDropdown />
                    </li>
                    {/* Ï */}
                    <li className='login-item js-login-item'>
                      <button
                        className='btn rounded-0 item js-login-link-item nowrap'
                        disabled={!user?.isUser}
                      >
                        <NavLink to={ACCOUNT_PATH.account()}>Account</NavLink>
                      </button>
                    </li>
                    <li className='login-item js-login-item'>
                      <button
                        type={'button'}
                        className='btn rounded-0 item js-login-link-item nowrap'
                        onClick={() => setShowCart(true)}
                        disabled={!user?.isUser}
                      >
                        My Bag{' '}
                        {user?.isUser && (
                          <span className='text-danger'>
                            {cartItems?.length ? <>({cartItems.length})</> : null}
                          </span>
                        )}
                      </button>
                    </li>
                    {user?.isUser ? (
                      <li className='login-item js-login-item'>
                        <button
                          type={'button'}
                          className='btn rounded-0 item js-login-link-item'
                          onClick={() => logout()}
                          // disabled={!user?.isUser}
                        >
                          Logout
                        </button>
                      </li>
                    ) : (
                      <li className='login-item js-login-item login-popup-wrap'>
                        <button
                          className={`btn item js-login-link-item ${isLoginOpen ? 'active' : ''}`}
                          onClick={handleLoginPopup}
                        >
                          {isLoginOpen ? (
                            <span onClick={handleLoginPopup} className='text-danger'>
                              X
                            </span>
                          ) : (
                            'Login'
                          )}
                        </button>
                        {isLoginOpen && <LoginPopup />}
                      </li>
                    )}
                  </ul>
                ) : (
                  <ul className='login'>
                    <li className='login-item js-login-item login-popup-wrap'>
                      <button
                        className={`btn item js-login-link-item ${isLoginOpen ? 'active' : ''}`}
                        onClick={handleLoginPopup}
                      >
                        {isLoginOpen ? (
                          <span onClick={handleLoginPopup} className='text-danger'>
                            X
                          </span>
                        ) : (
                          'Login'
                        )}
                      </button>
                      {isLoginOpen && <LoginPopup />}
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </nav>
        <CheckoutModal show={user?.isUser && showCart} setShow={setShowCart} />
      </header>
    </>
  )
}

export default Header
