import { useMemo } from 'react'
import { useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
import { useParent } from '../../contexts/ParentContext'
import useParentStore from '../../store/useParentStore'
import { ACCOUNT_PATH } from '../../router/paths'
import { SESSION_STORAGE_KEYS } from '../../config/constants'
const ParentDropdown = () => {
  const { parentId, setParentId } = useParent()
  const parents = useParentStore((state) => state.parents)
  const parentMap = useParentStore((state) => state.parentMap)
  const location = useLocation()
  const { parentId: _parentId } = useParams()

  const isDisabled = useMemo(() => {
    const url = location.pathname
    if (url.startsWith('/account/list')) {
      return false
    } else if (url.startsWith('/account/account/order-detail')) {
      return true
    } else if (url.startsWith('/account/account/checkout/')) {
      return true
    } else if (url.startsWith('/account/account')) {
      return false
    } else if (url === '/' || url === ACCOUNT_PATH.shopAll) {
      return false
    }
    return true
  }, [location.pathname])

  /*
   * change parentId if
   * parent dropdown is enbled for this path and
   * set first parent from list of parents
   */
  useEffect(() => {
    const firstParentId = parents[0]?.id
    if (parentId) {
      return
    }
    if (!isDisabled && firstParentId) {
      setParentId(firstParentId)
    }
  }, [parents, isDisabled])

  /*
   * change parentId if
   * url params { _parentId } change and
   * prevoius and new parentId is different
   */
  useEffect(() => {
    if (parentId == _parentId) {
      return
    }
    if (_parentId) {
      setParentId(_parentId)
    }
  }, [location.pathname])

  /*
   * store parentId in session storage if
   * change
   */
  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.PARENT_ID, String(parentId))
  }, [parentId])

  return (
    <div>
      <Dropdown className={'custom-dropdown nav-dropdown'}>
        <Dropdown.Toggle
          variant='link'
          id='dropdown-basic'
          className={'btn list-item text-truncate'}
          disabled={isDisabled}
        >
          <i className='icon-store align-middle text-danger'></i>{' '}
          {parentMap[parentId]?.accountName || 'Select Parent'}
        </Dropdown.Toggle>
        <Dropdown.Menu className='sub-header-dropdown'>
          {parents.map((parent) => (
            <Dropdown.Item key={parent.id} onClick={() => setParentId(parent.id)}>
              {parent.accountName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default ParentDropdown
